import { v4 as uuidv4 } from 'uuid';
import store from '@/reducer/store';
import { updatePoint, setCurrentItem, setCurrentGroupItem, updatePolyline, updateRectangle, deleteRectangle, addPolyline } from '@/reducer/mapSlice';
import { isPointInPolygon } from 'geolib';
import { markerTypes, polygonTypes, polylineTypes, rectangleTypes, siteTypes } from '@/page/EditMap/EditMapUtils.js';
import { apiDeviceAutoLocalization, apiBindDeviceStatus } from '@/cloud_api/device.js';
import { setSelectRobot } from '@/reducer/bindMapSlice.js';
import MapStatus from './Variable.js';
import {
  createPolygonNodePoint,
  createLineNodePoint,
  createPolygon,
  createPolyline,
  createPassage,
  createPoint,
  updateAddPolygon,
  updateAddPolyline,
  updateAddPassage,
  updateAddGate,
  getLatLngDistance,
  setLatLngWithDistance,
  setEditNodePoint,
  clearEditNodePoint,
  calculateExtendPoint,
  calculatePassageNode,
  getOriginLeafletPosition,
  setUVCZonePoint,
  getRotateLeafletPosition,
  createSitePoint,
  // createSite,
  createRectangleNodePoint,
  calculateRectangleCenter,
  modifyRectangleSize,
  groupByPathLine,
  recoveryPointNotSelect,
  createTaskPoint,
  createDockPoint,
  createHomePoint,
  getCenterLatLng,
  createPointByRedux,
  createLineByRedux,
  createGate,
  rebindIotGateMapArea,
  updateStepsInRedux,
  getGroupLayers,
  clearLayerLine,
  checkMarkerGroupInMapArea,
  disableNarrowGateBorder,
  checkLayerSize,
  clearUVCZoneList,
  drawLayerLine,
  createAnchorLine,
  clearAllScanPoint,
  setScanPointIntoMap,
} from './Utils.js';
import IconStyle from './IconStyle.js';

// 目前是否有 node 正在拖曳
let nodeIsDrag = false;

// 計算 point modal 位置
export const checkQuadrant = (x) => {
  const currentWidth = window.screen.width;
  const center = currentWidth / 2;
  let modalX = 0;

  if (x < center) modalX = x + 300; else modalX = x - 350;

  return { x: modalX };
};

// tool 為線段(line)模式時，地圖的 mouse move 事件
export const lineModeMouseEvent = () => {
  MapStatus.map.on('mousemove', (e) => {
    const { latlng } = e;
    switch (MapStatus.layerType) {
      case 'VirtualWall':
        updateAddPolyline(MapStatus.addLayer, latlng);
        break;
      case 'Passage':
        updateAddPassage(MapStatus.addLayer, latlng);
        break;
      case 'NarrowGate':
        updateAddPolyline(MapStatus.addLayer, latlng);
        updateAddGate(MapStatus.currentGate, latlng);
        break;
      case 'IotGate':
        updateAddPolyline(MapStatus.addLayer, latlng);
        updateAddGate(MapStatus.currentGate, latlng);
        break;
      case 'NarrowLane':
        updateAddPassage(MapStatus.addLayer, latlng);
        break;
      default:
        break;
    }
  });
};

// 新增 Gate
export const createNewGate = (setCreateLayerOpen) => {
  // 第一點建立 Gate，第二點完成
  if (MapStatus.nodeArr.length === 1) {
    createPolyline(MapStatus.layerType);
    createGate(MapStatus.layerType);
    lineModeMouseEvent();
  } else if (MapStatus.nodeArr.length === 2) {
    const originalLatLng = MapStatus.addLayer.getLatLngs().map((item) => getOriginLeafletPosition(item, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle));
    MapStatus.addLayer.options.originalLatLng = originalLatLng;
    setCreateLayerOpen(true);
  }
};

// 新增 Passage
export const createNewPassage = (setCreateLayerOpen) => {
  // 第一點建立 Passage，第二點完成
  if (MapStatus.nodeArr.length === 1) {
    createPassage(MapStatus.layerType);
    lineModeMouseEvent();
  } else if (MapStatus.nodeArr.length === 2) {
    const originalLatLng = MapStatus.addLayer.getLatLngs()[0].map((item) => getOriginLeafletPosition(item, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle));
    MapStatus.addLayer.options.originalLatLng = originalLatLng;

    // save to redux
    const layer = MapStatus.addLayer;
    const { id, type, toolTip } = layer.options;
    const nodeArr = MapStatus.nodeArr.map((node) => node._leaflet_id);
    const nodes = layer.getLatLngs()[0].map((node) => ({ lat: node.lat, lng: node.lng }));
    store.dispatch(addPolyline({ _id: layer._leaflet_id, id, type, name: toolTip, apiType: 'post', nodes }));
    store.dispatch(setCurrentItem({ _id: layer._leaflet_id, type, mode: 'add', nodeArr }));
    setCreateLayerOpen(true);
  }
};

// 新增 VirtualWall
export const createVirtualWall = (setCreateLayerOpen) => {
  // 第一點建立 polyline，第二點打開 create layer modal
  if (MapStatus.nodeArr.length === 1) {
    createPolyline(MapStatus.layerType);
  } else if (MapStatus.nodeArr.length === 2) {
    const originalLatLng = MapStatus.addLayer.getLatLngs().map((item) => getOriginLeafletPosition(item, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle));
    MapStatus.addLayer.options.originalLatLng = originalLatLng;

    // save to redux
    const layer = MapStatus.addLayer;
    const { id, type, toolTip } = layer.options;
    const nodeArr = MapStatus.nodeArr.map((node) => node._leaflet_id);
    const nodes = layer.getLatLngs().map((node) => ({ lat: node.lat, lng: node.lng }));
    store.dispatch(addPolyline({ _id: layer._leaflet_id, id, type, name: toolTip, apiType: 'post', nodes }));
    store.dispatch(setCurrentItem({ _id: layer._leaflet_id, type, mode: 'add', nodeArr }));
    setCreateLayerOpen(true);
  }

  lineModeMouseEvent();
};

// 新增 home point 事件
export const addHomePoint = (stateSet) => {
  MapStatus.addType = 'point';

  MapStatus.map.on('click', (e) => {
    // 抓取點擊位置座標並產生 node point
    const { latlng } = e;

    createHomePoint(latlng, 'Home', stateSet);
    MapStatus.isPathModeEdit = true;
  });
};

export const initReduxEvent = (type, option = {}) => {
  const { defaultData } = option;
  MapStatus.map.off('click');
  MapStatus.map.on('click', (e) => {
    // 抓取點擊位置座標並產生 node point

    if (MapStatus.mode === 'path') return;

    const { latlng } = e;
    const { x } = checkQuadrant(e.originalEvent.x);

    const newData = { ...defaultData };
    if (MapStatus.layerType === 'Elevator') {
      newData.exit_tmp_id = uuidv4();
      newData.enter_tmp_id = uuidv4();
      newData.inside_tmp_id = uuidv4();
    } else if (markerTypes.includes(MapStatus.layerType)) {
      newData.inside_tmp_id = uuidv4();
      newData.modalOffset = { x };
    }

    if (type === 'point') createPointByRedux(latlng, MapStatus.layerType, newData, true);
    if (type === 'polyline') createLineByRedux(latlng, MapStatus.layerType, defaultData, { ...option, positionX: checkQuadrant(e.originalEvent.x).x });
  });
};

// 新增 dock point 事件
export const addDockPoint = (stateSet) => {
  MapStatus.addType = 'point';

  MapStatus.map.on('click', (e) => {
    // 抓取點擊位置座標並產生 node point
    const { latlng } = e;

    createDockPoint(latlng, 'Dock', stateSet);
    MapStatus.isPathModeEdit = true;
  });
};

// 新增 task point 事件 offline mode
export const addTaskPointOff = (stateSet, baseMapControl) => {
  MapStatus.addType = 'point';

  MapStatus.map.on('click', (e) => {
    if (MapStatus.selectPath.pathId === '' && MapStatus.pathData.length !== 0) {
      stateSet.setTipText('TOOL_SELECT_PATH');

      const clearTip = setTimeout(() => {
        stateSet.setTipText('');
        clearTimeout(clearTip);
      }, 5000);
      return;
    }

    if (MapStatus.pathData.length === 0) {
      const newPath = JSON.parse(JSON.stringify(MapStatus.pathObj));
      const arr = [...MapStatus.pathData];
      const selectObj = { ...MapStatus.selectPath };
      newPath.pathId = uuidv4();
      selectObj.pathId = newPath.pathId;
      selectObj.pointId = '';
      selectObj.type = 'currentPoint';
      arr.push(newPath);

      stateSet.setPathData(arr);
      MapStatus.pathData = arr;
      stateSet.setSelectPath(selectObj);
      MapStatus.selectPath = selectObj;
    }

    // 抓取點擊位置座標並產生 node point
    const { latlng } = e;
    createTaskPoint(latlng, MapStatus.layerType, stateSet, 90, baseMapControl);
    MapStatus.isPathModeEdit = true;
  });
};

// 新增 Site 事件
export const addSite = (setState) => {
  MapStatus.addType = 'site';
  MapStatus.map.on('click', (e) => {
    if (MapStatus.addLayer !== null) return;

    // 抓取點擊位置座標並產生 node point
    const { latlng } = e;
    // Charger ParkingArea latlng 使用旋轉前的 leaflet 座標
    const originalLatLng = getOriginLeafletPosition(latlng, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);
    const { x } = checkQuadrant(e.originalEvent.x);
    const mapAngle = store.getState().mapSlice.mapRotateAngle;
    switch (MapStatus.layerType) {
      case 'Charger':
        // createSite(latlng, MapStatus.layerType);
        createSitePoint(originalLatLng, 'Charger', (MapStatus.map.getZoom() + 100) / 100, mapAngle, 90 - mapAngle, 16);
        setState((state) => ({
          ...state,
          pointType: MapStatus.layerType,
          createModalOpen: true,
          positionX: x,
        }));
        break;
      case 'ParkingArea':
        createSitePoint(originalLatLng, 'ParkingArea', null, mapAngle, 90 - mapAngle);
        setState((state) => ({
          ...state,
          pointType: MapStatus.layerType,
          createModalOpen: true,
          positionX: x,
        }));
        break;
      default:
        createPoint(latlng, MapStatus.layerType);
        setState(true);
        break;
    }
  });
};

// 新增 Line 事件
export const addLine = (setCreateLayerOpen) => {
  MapStatus.addType = 'line';

  MapStatus.map.on('click', (e) => {
    // 抓取點擊位置座標並產生 node point
    const { latlng } = e;

    switch (MapStatus.layerType) {
      case 'VirtualWall':
        createLineNodePoint(latlng);
        createVirtualWall(setCreateLayerOpen);
        break;
      case 'Passage':
        createLineNodePoint(latlng);
        createNewPassage(setCreateLayerOpen);
        break;
      case 'NarrowLane':
        createLineNodePoint(latlng);
        createNewPassage(setCreateLayerOpen);
        break;
      default:
        break;
    }
  });
};

// 新增 polygon 事件
export const addPolygon = (setState, option) => {
  MapStatus.addType = 'polygon';
  MapStatus.map.on('click', (e) => {
    // 若有 node 正在拖曳則不新增 node point
    if (nodeIsDrag) return;

    // 抓取點擊位置座標並產生 node point
    const { latlng } = e;
    createPolygonNodePoint(latlng, true, setState.setCreateLayerOpen, option, setState.setTipText);

    // 當 node point 出現第二點時產生 Polygon
    // 第三點以上則直接更新 layer
    if (MapStatus.nodeArr.length === 2) {
      createPolygon(MapStatus.layerType, 'post', option);
    } else if (MapStatus.nodeArr.length > 2) {
      updateAddPolygon(MapStatus.addLayer, latlng);
    }
  });
};
// 新增 polygon 事件
export const addPolygonIotGate = (setCreateLayerOpen, option) => {
  MapStatus.addType = 'polygon';
  MapStatus.map.on('click', (e) => {
    // 若有 node 正在拖曳則不新增 node point
    if (nodeIsDrag) return;
    // 若點擊的 Polygon 為 MapArea 則不在新增 node poit
    const MapArea = Object.values(MapStatus.map._layers)
      .filter((layer) => layer.options.name?.includes(('MapArea')) || layer.options?.name === 'door')
      .find((layers) => isPointInPolygon(e.latlng, layers.getLatLngs()[0]));
    if (MapArea !== undefined) return;
    const polygons = [...store.getState().mapSlice.polygons].filter((item) => item.name.includes('MapArea'));
    const name = 'MapArea'.concat(polygons.length + 1);

    // 抓取點擊位置座標並產生 node point
    const { latlng } = e;
    createPolygonNodePoint(latlng, true, setCreateLayerOpen, { ...option, name });

    // 當 node point 出現第二點時產生 Polygon
    // 第三點以上則直接更新 layer
    if (MapStatus.nodeArr.length === 2) {
      createPolygon(MapStatus.layerType, 'post', { name, toolTip: name });
    } else if (MapStatus.nodeArr.length > 2) {
      updateAddPolygon(MapStatus.addLayer, latlng);
    }
  });
};

/**
 * 在 MapStatus.map 新增事件監聽，用於新增 Rectangle。
 * @param {('LowSpeed'|'Slope'|'OneWay'|'NonAvoidance')} layerType 矩形的類別
 * @param {Function} setCreateLayerOpen 用於開啟建立新塗層彈出視窗的 callback
 */
export const addRectangle = (layerType, setCreateLayerOpen) => {
  MapStatus.addType = 'rectangle';

  MapStatus.map.on('click', (e) => {
    // 抓取點擊位置座標並產生 node point
    const { latlng, target } = e;
    if (MapStatus.nodeArr.length === 0) {
      if (target._leaflet_id !== MapStatus.map._leaflet_id) return;
      createRectangleNodePoint(latlng, layerType, false);
      const { _id, nodeArr } = store.getState().mapSlice.currentItem;
      const layer = MapStatus.map._layers[_id];
      MapStatus.map.on('mousemove', (_e) => {
        if (nodeArr == null || nodeArr.length === 0) return;
        const newLatLng = [...layer.getLatLngs()[0]];
        // 抓取滑鼠游標位置座標並產生 rectangle
        const { latlng: _latlng } = _e;
        newLatLng[3] = _latlng;
        modifyRectangleSize(3, newLatLng, layer);
        nodeArr.forEach((node, i) => {
          if (i >= 4) {
            console.warn(`Rectangle should not contain node with index ${i}`);
            return;
          }
          const marker = MapStatus.map._layers[node];
          marker.setLatLng(newLatLng[i]);
        });
      });
      return;
    }
    // 如果只單純 click 沒有拖曳的話，座標會只有三個，因此不產生矩形
    if (MapStatus.addLayer.getLatLngs()[0].length < 4) {
      store.dispatch(deleteRectangle({ _id: MapStatus.addLayer._leaflet_id }));
      MapStatus.addLayer.remove();
      MapStatus.nodeArr.forEach((node) => { node.remove(); });
      MapStatus.nodeArr = [];
      MapStatus.originRectangleNode = [];
      MapStatus.map.off('click');
      MapStatus.map.off('mousemove');
      setCreateLayerOpen(true);
      addRectangle(layerType, setCreateLayerOpen);
      return;
    }

    const { centerPosition } = MapStatus;
    const { rotationAngle } = MapStatus.mapMarker.options;
    const currentLatLng = MapStatus.addLayer.getLatLngs()[0];
    const originalLatLng = currentLatLng.map((item) => getOriginLeafletPosition(item, centerPosition, rotationAngle));

    MapStatus.addLayer.options.originalLatLng = originalLatLng;
    MapStatus.addLayer.options.notRotateLatLng = currentLatLng;
    calculateRectangleCenter(MapStatus.addLayer);
    setCreateLayerOpen(true);
  });
};

// 建立 node point 拖曳事件
export const nodeMarkerDrag = (node, type = 'add', setIsStepEdit, setEditLayerData) => {
  let layer;
  let undoLatLng;
  // 當拖曳開始時，nodeIsDrag 狀態改為：正在拖曳
  node.on('dragstart', () => {
    layer = MapStatus.addLayer;
    const { centerPosition } = MapStatus;
    const { rotationAngle } = MapStatus.mapMarker.options;
    if (type === 'edit') layer = MapStatus.editLayer;
    if (layer !== null) {
      layer.options.isEditToolTip = false;
      layer.options.isEditType = false;
      const getLatLngs = layer.getLatLngs();
      // length 為 1 時是 polygon
      if (getLatLngs.length === 1) {
        layer.options.undoLatLng = getLatLngs[0].map((item) => getOriginLeafletPosition(item, centerPosition, rotationAngle));
        layer.options.oldLatLng = getLatLngs[0].map((item) => item);
      } else {
        layer.options.undoLatLng = getLatLngs.map((item) => getOriginLeafletPosition(item, centerPosition, rotationAngle));
        layer.options.oldLatLng = getLatLngs;
      }
      undoLatLng = layer.options.undoLatLng;
    }
    nodeIsDrag = true;
  });

  // 拖曳期間即時更新目前新的 layer
  node.on('drag', (e) => {
    const { latlng } = e;
    const { index } = e.target.options;
    const layerType = layer.options.type;
    let getLatLng = [];
    switch (true) {
      case layerType === 'VirtualWall' || layerType === 'NarrowGate' || layerType === 'IotGate':
        getLatLng = layer.getLatLngs();
        break;
      case layerType === 'Passage' || layerType === 'NarrowLane':
        getLatLng = [...layer.options.node];
        break;
      default:
        getLatLng = [...layer.getLatLngs()[0]];
        break;
    }
    getLatLng[index] = latlng;
    // 關閉 tool tip
    if (type === 'edit') layer.closeTooltip();

    switch (true) {
      case layerType === 'Passage' || layerType === 'NarrowLane':
        // 取得 Passage 的四個點座標
        const passageLatLng = calculateExtendPoint(getLatLng);
        layer.options.node = getLatLng;
        layer.setLatLngs(passageLatLng);
        break;
      case layerType === 'NarrowGate' || layerType === 'IotGate':
        // 取得 NarrowGate 的四個點座標
        const narrowGateLatLng = calculateExtendPoint(getLatLng, 0.8);
        MapStatus.currentGate.setLatLngs(narrowGateLatLng);
        layer.setLatLngs(getLatLng);
        break;
      case rectangleTypes.includes(layerType):
        modifyRectangleSize(index, getLatLng, MapStatus.editLayer);
        const originalLatLng = layer.getLatLngs()[0].map((item) => getOriginLeafletPosition(item, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle));
        const updatedLatLng = layer.getLatLngs()[0].map((item) => ({ lat: Number(item.lat.toFixed(2)), lng: Number(item.lng.toFixed(2)) }));
        const length = Number(Math.abs(originalLatLng[0].lng - originalLatLng[2].lng).toFixed(1));
        const width = Number(Math.abs(originalLatLng[0].lat - originalLatLng[2].lat).toFixed(1));

        MapStatus.map.eachLayer((_layer) => {
          if (_layer.options.id === layer.options.id && _layer.options.type === 'OneWayArrow') {
            const center = layer.getBounds().getCenter();
            _layer.setLatLng(center);
            _layer._icon.style.transformOrigin = '';
          }
        });
        store.dispatch(updateRectangle({ _id: layer._leaflet_id, nodes: updatedLatLng, length, width }));

        setEditLayerData((prev) => ({
          ...prev,
          data: {
            id: layer.options.id,
            UVC_id: '',
            latlng: layer.getLatLngs(),
          },
        }));

        break;
      case layerType === 'Disinfection':
        const { centerPosition } = MapStatus;
        const { rotationAngle } = MapStatus.mapMarker.options;
        const UVCZone = MapStatus.map._layers[layer.options.UVCZoneId];
        const latLng = layer.getLatLngs()[0].map((item) => getOriginLeafletPosition(item, centerPosition, rotationAngle));
        const UVC_latlng = setUVCZonePoint(latLng);
        const UVC_rotate_latlng = UVC_latlng.map((item) => getRotateLeafletPosition(item, centerPosition, rotationAngle));
        if (!JSON.stringify(UVC_rotate_latlng).includes('null')) {
          UVCZone.setLatLngs(UVC_rotate_latlng);
          UVCZone.options.originalLatLng = UVC_latlng.map((item) => getOriginLeafletPosition(item, centerPosition, 0));
        }
        layer.setLatLngs(getLatLng);
        break;
      default:
        layer.setLatLngs(getLatLng);
        break;
    }
  });

  // 當拖曳結束時，nodeIsDrag 狀態改為：未拖曳
  node.on('dragend', () => {
    const { centerPosition } = MapStatus;
    const { rotationAngle } = MapStatus.mapMarker.options;
    const layerType = layer.options.type;
    if (MapStatus.editLayer !== null && checkLayerSize(MapStatus.editLayer)) {
      setIsStepEdit(true);
      return;
    }
    // 如果是拖曳編輯模式的 node point 當結束拖曳時建立 step
    if (type === 'edit') {
      const getUpLatLngs = layer.getLatLngs();
      if (getUpLatLngs.length === 1) {
        layer.options.redoLatLng = getUpLatLngs[0].map((item) => getOriginLeafletPosition(item, centerPosition, rotationAngle));
        switch (true) {
          case polygonTypes.includes(layerType):
            updateStepsInRedux('nodeMove', { ...layer.options, originalLatLng: undoLatLng }, { ...layer.options, originalLatLng: layer.options.redoLatLng });
            break;
          case rectangleTypes.includes(layerType):
            calculateRectangleCenter(MapStatus.editLayer);
            const { center, rosAngle } = layer.options;
            const notRotateLatLng = layer.getLatLngs()[0].map((item) => getRotateLeafletPosition(item, center, 360 - rosAngle));
            layer.options.notRotateLatLng = notRotateLatLng;
            updateStepsInRedux('nodeMove', { ...layer.options, originalLatLng: undoLatLng }, { ...layer.options, originalLatLng: layer.options.redoLatLng });
            MapStatus.originRectangleNode = layer.getLatLngs()[0].map((item) => item);
            break;
          case polylineTypes.includes(layerType):
            updateStepsInRedux('nodeMove', { ...layer.options, originalLatLng: undoLatLng }, { ...layer.options, originalLatLng: layer.options.redoLatLng });
            break;
          default:
            break;
        }
      } else {
        layer.options.redoLatLng = getUpLatLngs.map((item) => getOriginLeafletPosition(item, centerPosition, rotationAngle));
        if (polylineTypes.includes(layerType)) {
          updateStepsInRedux('nodeMove', { ...layer.options, originalLatLng: undoLatLng }, { ...layer.options, originalLatLng: layer.options.redoLatLng });
        }
      }
      layer.options.originalLatLng = layer.options.redoLatLng;
      setIsStepEdit(true);

      // 如果目前標記名稱是打開的話則重新設定 tool tip 位置
      if (MapStatus.toolTip) layer.openTooltip();
    }
    if (layerType === 'MapArea') rebindIotGateMapArea();
    const nodeStatus = setTimeout(() => {
      nodeIsDrag = false;
      clearTimeout(nodeStatus);
    }, 1);
  });
};
// layer 建立完成後的編輯監聽事件
export const layerEditEvent = (layer, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectLayerType) => {
  layer.on('mousedown', (e) => {
    const isAddModeOrPathMode = MapStatus.mode === 'add' || MapStatus.mode === 'path';
    if ((isAddModeOrPathMode && e.target.options?.type !== 'MapArea') || store.getState().mapSlice.tools.addOnly) return;
    MapStatus.layerArr.parkingAreaPoint.forEach((item) => {
      item.remove();
    });
    MapStatus.layerArr.parkingAreaPoint = [];

    MapStatus.mode = 'edit';
    MapStatus.editLayer = null;
    MapStatus.map.off('mousemove');

    if (setLayerToolMode) setLayerToolMode('select');

    const { target, latlng } = e;
    const {
      id,
      groupID,
      UVC_id,
      type,
      toolTip,
      nodeArr,
      originalLatLng,
      rosAngle,
    } = target.options;
    MapStatus.editLayer = target;

    // 清除 node point 和監聽
    clearEditNodePoint();
    // 清除Layer輔助線
    clearLayerLine();
    // 關閉排序畫面
    clearUVCZoneList(false);
    // 先清除畫面上所有窄門框線
    disableNarrowGateBorder();

    // 清除 layers
    if (MapStatus.currentGate !== null) {
      MapStatus.currentGate.remove();
      MapStatus.currentGate = null;
    }
    // TODO: 避免先選取充電站或駐停區再點選其他 layer 做刪除時，將上個點選的駐停區或充點站也誤刪之問題，
    // TODO: 後續所有 layer 資料統整至 Redux 時需再調整
    if (type !== 'Charger' || type !== 'ParkingArea') {
      const current = store.getState().mapSlice.currentItem;
      store.dispatch(setCurrentItem({ ...current, mode: '' }));
      if (type !== 'IotGate') store.dispatch(setCurrentGroupItem({}));
    }

    switch (true) {
      case rectangleTypes.includes(type):
        MapStatus.editType = 'rectangle';
        setEditNodePoint(target, setIsStepEdit, setEditLayerData);
        setLayerEvent(target, latlng, setIsStepEdit, setEditLayerData);
        setEditLayerData({
          flagType: 'rectangle',
          layerType: type,
          layerName: toolTip,
          data: {
            id,
            UVC_id: '',
            latlng: target.getLatLngs(),
          },
        });
        break;
      case type === 'OneWayArrow':
        let oneWay;
        MapStatus.map.eachLayer((item) => {
          if (item.options.type === 'OneWay' && item.options.id === id) {
            oneWay = item;
          }
        });

        MapStatus.editType = 'rectangle';
        MapStatus.editLayer = oneWay;
        setEditNodePoint(oneWay, setIsStepEdit, setEditLayerData);
        setLayerEvent(oneWay, latlng, setIsStepEdit, setEditLayerData);

        setEditLayerData({
          flagType: 'rectangle',
          layerType: oneWay.options.type,
          layerName: oneWay.options.toolTip,
          data: {
            id: oneWay.options.id,
            UVC_id: '',
            latlng: oneWay.getLatLngs(),
          },
        });
        break;
      case type === 'NarrowGate':
        MapStatus.editType = 'line';
        const { gate } = getGroupLayers(target.options.id, 'NarrowGate');
        const { name } = store.getState().mapSlice.polylines.find((polyline) => polyline._id === target._leaflet_id);
        setNarrowGateEvent(target, latlng, type, nodeArr);
        setEditLayerData({
          flagType: 'line',
          layerType: type,
          layerName: name,
          data: {
            id,
            latlng: target.getLatLngs(),
          },
        });
        store.dispatch(setCurrentItem({ _id: target._leaflet_id, type, mode: 'edit', nodeArr }));
        gate.setStyle({ color: '#FF8800' });
        break;
      case type === 'IotGate':
        MapStatus.editType = 'line';
        setIotGateEvent(target, latlng, type, nodeArr);
        if (setLayerToolMode) setLayerToolMode(null);
        MapStatus.mode = null;
        if (setSelectLayerType) setSelectLayerType(null);
        break;
      case type === 'MapArea':
        setEditNodePoint(target, setIsStepEdit);
        setLayerEvent(target, latlng, setIsStepEdit);
        MapStatus.editType = 'MapArea';
        setEditLayerData({
          flagType: 'polygon',
          layerType: 'MapArea',
          layerName: toolTip,
          data: {
            _id: target._leaflet_id,
            id,
            latlng: target.getLatLngs(),
          },
        });
        break;
      case type === 'Dock' || type === 'Home' || type === 'Elevator':
        MapStatus.editType = 'point';
        setEditLayerData({
          flagType: 'point',
          layerType: type,
          layerName: toolTip,
          data: {
            id,
            latlng: originalLatLng,
            angle: rosAngle,
          },
        });
        break;
      case polylineTypes.includes(type):
        setEditNodePoint(target, setIsStepEdit);
        setLayerEvent(target, latlng, setIsStepEdit);
        MapStatus.editType = 'line';
        setEditLayerData({
          flagType: 'line',
          layerType: type,
          layerName: toolTip,
          data: {
            id,
            latlng: target.getLatLngs(),
          },
        });
        break;
      case polygonTypes.includes(type):
        setEditNodePoint(target, setIsStepEdit);
        setLayerEvent(target, latlng, setIsStepEdit);
        MapStatus.editType = 'polygon';
        setEditLayerData({
          flagType: 'polygon',
          layerType: type,
          layerName: toolTip,
          data: {
            id,
            UVC_id: type === 'Disinfection' ? UVC_id : '',
            latlng: target.getLatLngs(),
          },
        });
        break;
      case siteTypes.includes(type):
        MapStatus.editType = 'site';
        setEditLayerData({
          flagType: 'site',
          layerType: type,
          layerName: toolTip,
          data: {
            id,
            latlng: originalLatLng,
            angle: rosAngle,
          },
        });
        store.dispatch(setCurrentGroupItem({ id: groupID, type, mode: 'edit' }));
        break;
      case markerTypes.includes(type):
        MapStatus.editType = 'point';
        setEditLayerData({
          flagType: 'point',
          layerType: type,
          layerName: toolTip,
          data: {
            id,
            latlng: originalLatLng,
            angle: rosAngle,
          },
        });

        if (type === 'Remark' || type === 'Elevator') {
          let targetPoint;
          if (type === 'Remark') {
            targetPoint = MapStatus.initPoint.find((item) => item.id === id);
          }

          if (targetPoint !== undefined) {
            const { parking_group_list } = targetPoint;
            parking_group_list.forEach((item) => {
              createSitePoint(item, 'ParkingArea');
            });
          }
        }
        break;
      default:
        break;
    }
  });
};

export const layerEditEventClickStart = (target, latlng, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectLayerType) => {
  const isAddModeOrPathMode = MapStatus.mode === 'add' || MapStatus.mode === 'path';
  if ((isAddModeOrPathMode && target.options?.type !== 'MapArea') || store.getState().mapSlice.tools.addOnly) return;
  MapStatus.layerArr.parkingAreaPoint.forEach((item) => {
    item.remove();
  });
  MapStatus.layerArr.parkingAreaPoint = [];

  MapStatus.mode = 'edit';
  MapStatus.editLayer = null;
  MapStatus.map.off('mousemove');

  if (setLayerToolMode) setLayerToolMode('select');

  const {
    id,
    groupID,
    UVC_id,
    type,
    toolTip,
    nodeArr,
    originalLatLng,
    rosAngle,
  } = target.options;
  MapStatus.editLayer = target;

  // 清除 node point 和監聽
  clearEditNodePoint();
  // 清除Layer輔助線
  clearLayerLine();
  // 關閉排序畫面
  clearUVCZoneList(false);
  // 先清除畫面上所有窄門框線
  disableNarrowGateBorder();

  // 清除 layers
  if (MapStatus.currentGate !== null) {
    MapStatus.currentGate.remove();
    MapStatus.currentGate = null;
  }
  // TODO: 避免先選取充電站或駐停區再點選其他 layer 做刪除時，將上個點選的駐停區或充點站也誤刪之問題，
  // TODO: 後續所有 layer 資料統整至 Redux 時需再調整
  if (type !== 'Charger' || type !== 'ParkingArea') {
    const current = store.getState().mapSlice.currentItem;
    store.dispatch(setCurrentItem({ ...current, mode: '' }));
    if (type !== 'IotGate') store.dispatch(setCurrentGroupItem({}));
  }

  switch (true) {
    case rectangleTypes.includes(type):
      MapStatus.editType = 'rectangle';
      setEditNodePoint(target, setIsStepEdit, setEditLayerData);
      setLayerEvent(target, latlng, setIsStepEdit, setEditLayerData);
      setEditLayerData({
        flagType: 'rectangle',
        layerType: type,
        layerName: toolTip,
        data: {
          id,
          UVC_id: '',
          latlng: target.getLatLngs(),
        },
      });
      break;
    case type === 'OneWayArrow':
      let oneWay;
      MapStatus.map.eachLayer((item) => {
        if (item.options.type === 'OneWay' && item.options.id === id) {
          oneWay = item;
        }
      });

      MapStatus.editType = 'rectangle';
      MapStatus.editLayer = oneWay;
      setEditNodePoint(oneWay, setIsStepEdit, setEditLayerData);
      setLayerEvent(oneWay, latlng, setIsStepEdit, setEditLayerData);

      setEditLayerData({
        flagType: 'rectangle',
        layerType: oneWay.options.type,
        layerName: oneWay.options.toolTip,
        data: {
          id: oneWay.options.id,
          UVC_id: '',
          latlng: oneWay.getLatLngs(),
        },
      });
      break;
    case type === 'NarrowGate':
      MapStatus.editType = 'line';
      const { gate } = getGroupLayers(target.options.id, 'NarrowGate');
      const { name } = store.getState().mapSlice.polylines.find((polyline) => polyline._id === target._leaflet_id);
      setNarrowGateEvent(target, latlng, type, nodeArr);
      setEditLayerData({
        flagType: 'line',
        layerType: type,
        layerName: name,
        data: {
          id,
          latlng: target.getLatLngs(),
        },
      });
      store.dispatch(setCurrentItem({ _id: target._leaflet_id, type, mode: 'edit', nodeArr }));
      gate.setStyle({ color: '#FF8800' });
      break;
    case type === 'IotGate':
      MapStatus.editType = 'line';
      setIotGateEvent(target, latlng, type, nodeArr);
      if (setLayerToolMode) setLayerToolMode(null);
      MapStatus.mode = null;
      if (setSelectLayerType) setSelectLayerType(null);
      break;
    case type === 'MapArea':
      setEditNodePoint(target, setIsStepEdit);
      setLayerEvent(target, latlng, setIsStepEdit);
      MapStatus.editType = 'MapArea';
      setEditLayerData({
        flagType: 'polygon',
        layerType: 'MapArea',
        layerName: toolTip,
        data: {
          _id: target._leaflet_id,
          id,
          latlng: target.getLatLngs(),
        },
      });
      break;
    case type === 'Dock' || type === 'Home' || type === 'Elevator':
      MapStatus.editType = 'point';
      setEditLayerData({
        flagType: 'point',
        layerType: type,
        layerName: toolTip,
        data: {
          id,
          latlng: originalLatLng,
          angle: rosAngle,
        },
      });
      break;
    case polylineTypes.includes(type):
      setEditNodePoint(target, setIsStepEdit);
      setLayerEvent(target, latlng, setIsStepEdit);
      MapStatus.editType = 'line';
      setEditLayerData({
        flagType: 'line',
        layerType: type,
        layerName: toolTip,
        data: {
          id,
          latlng: target.getLatLngs(),
        },
      });
      break;
    case polygonTypes.includes(type):
      setEditNodePoint(target, setIsStepEdit);
      setLayerEvent(target, latlng, setIsStepEdit);
      MapStatus.editType = 'polygon';
      setEditLayerData({
        flagType: 'polygon',
        layerType: type,
        layerName: toolTip,
        data: {
          id,
          UVC_id: type === 'Disinfection' ? UVC_id : '',
          latlng: target.getLatLngs(),
        },
      });
      break;
    case siteTypes.includes(type):
      MapStatus.editType = 'site';
      setEditLayerData({
        flagType: 'site',
        layerType: type,
        layerName: toolTip,
        data: {
          id,
          latlng: originalLatLng,
          angle: rosAngle,
        },
      });
      store.dispatch(setCurrentGroupItem({ id: groupID, type, mode: 'edit' }));
      break;
    case markerTypes.includes(type):
      MapStatus.editType = 'point';
      setEditLayerData({
        flagType: 'point',
        layerType: type,
        layerName: toolTip,
        data: {
          id,
          latlng: originalLatLng,
          angle: rosAngle,
        },
      });

      if (type === 'Remark' || type === 'Elevator') {
        let targetPoint;
        if (type === 'Remark') {
          targetPoint = MapStatus.initPoint.find((item) => item.id === id);
        }

        if (targetPoint !== undefined) {
          const { parking_group_list } = targetPoint;
          parking_group_list.forEach((item) => {
            createSitePoint(item, 'ParkingArea');
          });
        }
      }
      break;
    default:
      break;
  }
};

// layer 的編輯監聽事件
export const setLayerEvent = (layer, down_latlng, setIsStepEdit, setEditLayerData) => {
  const { centerPosition } = MapStatus;
  const { rotationAngle } = MapStatus.mapMarker.options;

  // 抓取滑鼠初始位置
  let ori_latlng = down_latlng;
  // 先將 layer 原本的狀態儲存起來
  layer.options.isEditToolTip = false;
  layer.options.isEditType = false;
  const getLatLngs = layer.getLatLngs();
  // length 為 1 時是 polygon
  if (getLatLngs.length === 1) {
    layer.options.undoLatLng = getLatLngs[0].map((item) => getOriginLeafletPosition(item, centerPosition, rotationAngle));
  } else {
    layer.options.undoLatLng = getLatLngs.map((item) => getOriginLeafletPosition(item, centerPosition, rotationAngle));
  }
  // 拖拉 layer 事件
  MapStatus.map.on('mousemove', (move) => {
    // 移動時關閉地圖拖拉功能、tool tip & 清除 node point
    MapStatus.map.dragging.disable();
    clearEditNodePoint();
    layer.closeTooltip();

    // 有觸發 mousemove 將 isEdit 變更為有編輯過
    layer.options.isEdit = true;

    // 抓取移動後滑鼠的座標
    const move_latlng = move.latlng;

    // 計算移動的距離
    const move_distance = getLatLngDistance(ori_latlng, move_latlng);

    // 計算 layer 各點的新座標
    const new_latlngs = [];
    let getLatLng = layer.getLatLngs()[0];

    switch (true) {
      case layer.options.type === 'VirtualWall':
        getLatLng = layer.getLatLngs();
        break;
      case layer.options.type === 'OneWay':
        MapStatus.map.eachLayer((item) => {
          if (item.options.type === 'OneWayArrow' && item.options.id === layer.options.id) {
            const new_latlng = setLatLngWithDistance(item.getLatLng(), move_distance);
            item.setLatLng(new_latlng);
            item._icon.style.transformOrigin = '';
          }
        });
        break;
      case layer.options.type === 'Disinfection':
        const UVCZone = MapStatus.map._layers[layer.options.UVCZoneId];
        const latLng = layer.getLatLngs()[0].map((item) => getOriginLeafletPosition(item, centerPosition, rotationAngle));
        const UVC_latlng = setUVCZonePoint(latLng);
        const UVC_rotate_latlng = UVC_latlng.map((item) => getRotateLeafletPosition(item, centerPosition, rotationAngle));
        if (!JSON.stringify(UVC_rotate_latlng).includes('null')) {
          UVCZone.setLatLngs(UVC_rotate_latlng);
          UVCZone.options.originalLatLng = UVC_latlng.map((item) => getOriginLeafletPosition(item, centerPosition, 0));
        }
        break;
      default:
        break;
    }

    getLatLng.forEach((item) => {
      const new_latlng = setLatLngWithDistance(item, move_distance);
      new_latlngs.push(new_latlng);
    });

    // 更新 layer 位置 & 更新滑鼠初始座標
    layer.setLatLngs(new_latlngs);
    ori_latlng = move_latlng;
  });

  // 結束拖拉 layer 事件
  MapStatus.map.once('mouseup', () => {
    MapStatus.map.off('mousemove');
    const newCenterPosition = MapStatus.centerPosition;
    const mapAngle = MapStatus.mapMarker.options.rotationAngle;

    // 恢復地圖拖拉功能
    MapStatus.map.dragging.enable();

    // 如果目前標記名稱是打開的話則重新設定 tool tip 位置
    if (MapStatus.toolTip) layer.openTooltip();

    // 如果是 Passage 則重新計算 node 座標
    if (layer.options.type === 'Passage' || layer.options.type === 'NarrowLane') calculatePassageNode(layer);

    // 如果是矩形的 type
    if (rectangleTypes.includes(layer.options.type)) {
      calculateRectangleCenter(layer);
      const { center, rosAngle } = layer.options;
      const notRotateLatLng = layer.getLatLngs()[0].map((item) => getRotateLeafletPosition(item, center, 360 - rosAngle));
      layer.options.notRotateLatLng = notRotateLatLng;
      MapStatus.originRectangleNode = layer.getLatLngs()[0].map((item) => item);

      const nodes = layer.getLatLngs()[0].map((item) => ({ lat: Number(item.lat.toFixed(2)), lng: Number(item.lng.toFixed(2)) }));

      store.dispatch(updateRectangle({ _id: layer._leaflet_id, nodes }));
    }

    // 恢復顯示 node point，同時將 nodeArr 儲存進 redux
    clearEditNodePoint();
    const nodeArr = setEditNodePoint(layer, setIsStepEdit, setEditLayerData);
    store.dispatch(setCurrentItem({ _id: layer._leaflet_id, type: layer.options.type, mode: 'edit', nodeArr }));

    // 如果有編輯過才加入 steps
    if (layer.options.isEdit) {
      // 將 layer 編輯後的的狀態儲存起來
      const getUpLatLngs = layer.getLatLngs();
      if (getUpLatLngs.length === 1) {
        layer.options.redoLatLng = getUpLatLngs[0].map((item) => getOriginLeafletPosition(item, newCenterPosition, mapAngle));
        if (layer.options.type === 'Disinfection') {
          const UVCZone = MapStatus.map._layers[layer.options.UVCZoneId];
          const UVC_latlng = setUVCZonePoint(layer.options.redoLatLng);
          const UVC_rotate_latlng = UVC_latlng.map((item) => getRotateLeafletPosition(item, newCenterPosition, mapAngle));
          UVCZone.setLatLngs(UVC_rotate_latlng);
          UVCZone.options.originalLatLng = UVC_latlng.map((item) => getOriginLeafletPosition(item, newCenterPosition, 0));
        }
      } else {
        layer.options.redoLatLng = getUpLatLngs.map((item) => getOriginLeafletPosition(item, newCenterPosition, mapAngle));
      }
      if ([...rectangleTypes, ...polylineTypes, ...polygonTypes].includes(layer.options.type)) {
        updateStepsInRedux('move', {
          ...layer.options,
          originalLatLng: layer.options.undoLatLng,
        }, {
          ...layer.options,
          originalLatLng: layer.options.redoLatLng,
        });
      }
      layer.options.originalLatLng = layer.options.redoLatLng;
      setIsStepEdit(true);
      layer.options.isEdit = false;
    }
    // 如果是地圖區塊的 type 需重新綁定閘門
    if (layer.options.type === 'MapArea') rebindIotGateMapArea();
  });
};

// NarrowGate 的編輯監聽事件
export const setNarrowGateEvent = (layer, down_latlng, type, nodeArr) => {
  const nodePoint = ['point1', 'point2'];
  const layers = getGroupLayers(layer.options.groupID, 'NarrowGate');
  const undoPayload = {};
  let redoPayload = {};
  let drag = false;

  MapStatus.map.dragging.disable();
  // 抓取滑鼠初始位置
  let ori_latlng = down_latlng;

  // 先將 layer 原本的狀態儲存起來
  layer.options.isEditToolTip = false;
  layer.options.isEditType = false;

  nodePoint.forEach((name) => { undoPayload[name] = layers[name].getLatLng(); });

  // 拖拉 layer 事件
  MapStatus.map.on('mousemove', (move) => {
    drag = true;
    // 移動時關閉地圖拖拉功能、tool tip & 清除 node point
    clearEditNodePoint();
    layer.closeTooltip();
    // 有觸發 mousemove 將 isEdit 變更為有編輯過
    layer.options.isEdit = true;

    // 抓取移動後滑鼠的座標
    const move_latlng = move.latlng;

    // 計算移動的距離
    const move_distance = getLatLngDistance(ori_latlng, move_latlng);

    const new_latlngs = [];
    layer.getLatLngs().forEach((item) => {
      const new_latlng = setLatLngWithDistance(item, move_distance);
      new_latlngs.push(new_latlng);
    });

    // 更新 layer 位置 & 更新滑鼠初始座標
    layer.setLatLngs(new_latlngs);
    const newGatePosition = [
      { lat: new_latlngs[0][0], lng: new_latlngs[0][1] },
      { lat: new_latlngs[1][0], lng: new_latlngs[1][1] },
    ];
    updateAddGate(layers.gate, newGatePosition);
    ori_latlng = move_latlng;

    // 隱藏point
    nodePoint.forEach((name) => {
      layers[name]._icon.style.display = 'none';
    });
  });

  // 結束拖拉 layer 事件
  MapStatus.map.once('mouseup', () => {
    MapStatus.map.off('mousemove');

    // 恢復地圖拖拉功能
    MapStatus.map.dragging.enable();

    // 如果目前標記名稱是打開的話則重新設定 tool tip 位置
    if (MapStatus.toolTip) layer.openTooltip();

    // 計算滑鼠最後位置
    const move_distance = getLatLngDistance(down_latlng, ori_latlng);

    // 顯示 point
    nodePoint.forEach((name) => {
      const new_latlng = setLatLngWithDistance(layers[name].getLatLng(), move_distance);
      layers[name].setLatLng(new_latlng);
      layers[name]._icon.style.display = '';
      store.dispatch(updatePoint({ _id: layers[name]._leaflet_id, ...layers[name].getLatLng() }));
    });
    store.dispatch(setCurrentItem({ _id: layer._leaflet_id, type, mode: 'edit', nodeArr }));

    if (drag) {
      redoPayload = { ...redoPayload, point1: layers.point1.getLatLng(), point2: layers.point2.getLatLng() };
      store.dispatch(updatePolyline({ _id: layer._leaflet_id, ...redoPayload }));
      updateStepsInRedux('move', undoPayload, redoPayload);
    }
  });
};

// layer 的編輯監聽事件
export const setIotGateEvent = (layer, down_latlng, type, nodeArr) => {
  const iotGatePolygon = ['insidePassageLength', 'outsidePassageLength', 'insideWorkingArea', 'outsideWorkingArea', 'door'];
  const iotGateWorkingPoint = ['insidePoint', 'outsidePoint'];
  const iotGatePolyline = ['polyline'];
  const iotGatePoint = ['point1', 'point2'];
  const layers = getGroupLayers(layer.options.groupID, 'IotGate');
  const group = store.getState().mapSlice.groups.find((item) => item.id === layer.options.groupID && item.type === 'IotGate' && item.apiType !== 'delete');
  let undoPayload = {};
  let redoPayload = {};
  let drag = false;

  MapStatus.map.dragging.disable();

  // 抓取滑鼠初始位置
  let ori_latlng = down_latlng;

  // 先將 layer 原本的狀態儲存起來
  layer.options.isEditToolTip = false;
  layer.options.isEditType = false;

  iotGatePolygon.forEach((name) => { undoPayload[name] = [...layers[name].getLatLngs()[0]]; });
  iotGatePolyline.forEach((name) => { undoPayload[name] = layers[name].getLatLngs(); });
  iotGateWorkingPoint.forEach((name) => { undoPayload[name] = layers[name].getLatLng(); });
  iotGatePoint.forEach((name) => { undoPayload[name] = layers[name].getLatLng(); });

  // 拖拉 layer 事件
  MapStatus.map.on('mousemove', (move) => {
    drag = true;
    // 移動時關閉地圖拖拉功能、tool tip & 清除 node point
    clearEditNodePoint();
    layer.closeTooltip();
    // 有觸發 mousemove 將 isEdit 變更為有編輯過
    layer.options.isEdit = true;

    // 抓取移動後滑鼠的座標
    const move_latlng = move.latlng;

    // 計算移動的距離
    const move_distance = getLatLngDistance(ori_latlng, move_latlng);

    iotGatePolygon.forEach((name) => {
      const new_latlngs = [];
      layers[name].getLatLngs()[0].forEach((item) => {
        const new_latlng = setLatLngWithDistance(item, move_distance);
        new_latlngs.push(new_latlng);
      });
      // 更新 layer 位置
      layers[name].setLatLngs(new_latlngs);
    });
    iotGatePolyline.forEach((name) => {
      const new_latlngs = [];
      layers[name].getLatLngs().forEach((item) => {
        const new_latlng = setLatLngWithDistance(item, move_distance);
        new_latlngs.push(new_latlng);
      });
      // 更新 layer 位置
      layers[name].setLatLngs(new_latlngs);
    });
    iotGateWorkingPoint.forEach((name) => {
      const new_latlng = setLatLngWithDistance(layers[name].getLatLng(), move_distance);
      // 更新 layer 位置
      layers[name].setLatLng(new_latlng);
    });

    ori_latlng = move_latlng;

    // 隱藏point
    iotGatePoint.forEach((name) => {
      layers[name]._icon.style.display = 'none';
    });
  });

  // 結束拖拉 layer 事件
  MapStatus.map.once('mouseup', () => {
    MapStatus.map.off('mousemove');

    // 恢復地圖拖拉功能
    MapStatus.map.dragging.enable();

    // 如果目前標記名稱是打開的話則重新設定 tool tip 位置
    if (MapStatus.toolTip) layer.openTooltip();

    // 計算滑鼠最後位置
    const move_distance = getLatLngDistance(down_latlng, ori_latlng);

    // 儲存拖曳位置
    iotGatePolygon.forEach((name) => { redoPayload[name] = [...layers[name].getLatLngs()[0]]; });
    iotGatePolyline.forEach((name) => { redoPayload[name] = layers[name].getLatLngs(); });
    iotGateWorkingPoint.forEach((name) => { redoPayload[name] = layers[name].getLatLng(); });

    // 顯示 point
    iotGatePoint.forEach((name) => {
      const new_latlng = setLatLngWithDistance(layers[name].getLatLng(), move_distance);
      layers[name].setLatLng(new_latlng);
      redoPayload[name] = layers[name].getLatLng();

      layers[name]._icon.style.display = '';
    });
    store.dispatch(setCurrentItem({ _id: layer.options.groupID, type, mode: 'edit', nodeArr }));
    store.dispatch(setCurrentGroupItem({ id: layer.options.groupID, type, mode: 'edit' }));

    if (drag) {
      rebindIotGateMapArea(group.id);
      store.dispatch(updatePoint({ _id: group.point1LeafletId, ...layers.point1.getLatLng() }));
      store.dispatch(updatePoint({ _id: group.point2LeafletId, ...layers.point2.getLatLng() }));
      updateStepsInRedux('move', undoPayload, redoPayload);
      undoPayload = {};
      redoPayload = {};
    }
  });
};

// 點擊到的 UVC Zone 加入排序功能 list
export const addUVCZoneItem = (target, setUVCZoneData) => {
  setUVCZoneData((prev) => {
    if (prev.some((item) => item?.options.id === target.options.UVC_id)) {
      return prev;
    }
    const disinfection = store.getState().mapSlice.polygons.find((item) => item.id === target.options.UVC_id);
    const disinfectionLayer = MapStatus.map._layers[disinfection._id];
    const center = getCenterLatLng(target.getLatLngs()[0]);
    disinfectionLayer.options.center = center;
    return [...prev, disinfectionLayer];
  });
};

// UVC Zone 的編輯監聽事件
export const UVCZoneEditEvent = (UVCZone, setLayerToolMode, setIsStepEdit, setEditLayerData, setUVCZoneData) => {
  UVCZone.on('mousedown', (e) => {
    if (store.getState().mapSlice.tools.addOnly) return;
    if (MapStatus.mode === 'add' || MapStatus.mode === 'path') return;

    // 打開排序功能
    if (MapStatus.isUVCZoneOrder) {
      addUVCZoneItem(e.target, setUVCZoneData);
      return;
    }

    const { latlng } = e;
    const { UVC_id } = e.target.options;

    MapStatus.mode = 'edit';
    MapStatus.editLayer = null;
    MapStatus.editType = 'polygon';
    MapStatus.map.off('mousemove');

    setLayerToolMode('select');

    const disinfection = store.getState().mapSlice.polygons.find((p) => p.id === UVC_id);
    const disinfectionLayer = MapStatus.map._layers[disinfection._id];

    store.dispatch(setCurrentItem({ _id: disinfection._id, type: disinfection.type, mode: 'edit', nodeArr: [] })); // TODO: add nodeArr back
    MapStatus.editLayer = disinfectionLayer;

    // 清除 node point 和監聽
    clearEditNodePoint();

    setEditNodePoint(disinfectionLayer, setIsStepEdit);
    setLayerEvent(disinfectionLayer, latlng, setIsStepEdit);
    setEditLayerData({
      flagType: 'polygon',
      layerType: disinfectionLayer.options.type,
      layerName: disinfectionLayer.options.toolTip,
      data: {
        id: disinfectionLayer.options.id,
        UVC_id: disinfectionLayer.options.type === 'Disinfection' ? disinfectionLayer.options.UVC_id : '',
        latlng: disinfectionLayer.getLatLngs(),
      },
    });
  });
};

// gate 的編輯監聽事件
export const gateEditEvent = (gate, layer, down_latlng, setIsStepEdit) => {
  const { centerPosition } = MapStatus;
  const { rotationAngle } = MapStatus.mapMarker.options;
  // 抓取滑鼠初始位置
  let ori_latlng = down_latlng;

  // 先將 layer 原本的狀態儲存起來
  layer.options.isEditToolTip = false;
  layer.options.isEditType = false;
  const getLatLngs = layer.getLatLngs();
  layer.options.undoLatLng = getLatLngs.map((item) => getOriginLeafletPosition(item, centerPosition, rotationAngle));

  // 拖拉 layer 事件
  MapStatus.map.on('mousemove', (move) => {
    // 移動時關閉地圖拖拉功能、tool tip & 清除 node point
    MapStatus.map.dragging.disable();
    clearEditNodePoint();
    layer.closeTooltip();

    // 有觸發 mousemove 將 isEdit 變更為有編輯過
    layer.options.isEdit = true;

    // 抓取移動後滑鼠的座標
    const move_latlng = move.latlng;

    // 計算移動的距離
    const move_distance = getLatLngDistance(ori_latlng, move_latlng);

    // 計算 layer 各點的新座標
    const new_latlngs = [];
    const getLatLng = layer.getLatLngs();
    const new_gateLatLngs = [];
    const getGateLatLng = gate.getLatLngs()[0];

    getLatLng.forEach((item) => {
      const new_latlng = setLatLngWithDistance(item, move_distance);
      new_latlngs.push(new_latlng);
    });

    getGateLatLng.forEach((item) => {
      const new_gateLatLng = setLatLngWithDistance(item, move_distance);
      new_gateLatLngs.push(new_gateLatLng);
    });

    // 更新 layer 位置 & 更新滑鼠初始座標
    layer.setLatLngs(new_latlngs);
    gate.setLatLngs(new_gateLatLngs);
    ori_latlng = move_latlng;
  });

  // 結束拖拉 layer 事件
  MapStatus.map.once('mouseup', () => {
    const newCenterPosition = MapStatus.centerPosition;
    const mapAngle = MapStatus.mapMarker.options.rotationAngle;
    MapStatus.map.off('mousemove');
    // 恢復地圖拖拉功能
    MapStatus.map.dragging.enable();

    // 如果目前標記名稱是打開的話則重新設定 tool tip 位置
    if (MapStatus.toolTip) layer.openTooltip();

    // 恢復顯示 node point
    clearEditNodePoint();
    setEditNodePoint(layer, setIsStepEdit);
    MapStatus.editLayer.options.node = MapStatus.nodeEditArr;
    MapStatus.editLayer.options.node.forEach((node) => {
      node.dragging = false;
      node.options.draggable = false;
      node.options.originalLatLng = getOriginLeafletPosition(MapStatus.editLayer.options.node[0].getLatLng(), newCenterPosition, mapAngle);
    });

    // 如果有編輯過才加入 steps
    if (layer.options.isEdit) {
      const getUpLatLngs = layer.getLatLngs();
      // 將 layer 編輯後的的狀態儲存起來
      layer.options.redoLatLng = getUpLatLngs.map((item) => getOriginLeafletPosition(item, newCenterPosition, mapAngle));
      layer.options.originalLatLng = layer.options.redoLatLng;
      setIsStepEdit(true);
      layer.options.isEdit = false;
    }
  });
};

// gate 的編輯 mousedown 事件
export const gateMousedown = (narrowGate, layer, setLayerToolMode, setIsStepEdit, setEditLayerData) => {
  narrowGate.on('mousedown', (e) => {
    const { latlng, target } = e;
    const { type, toolTip } = layer.options;
    MapStatus.editLayer = layer;

    // 清除 node point 和監聽
    clearEditNodePoint();

    setEditNodePoint(layer, setIsStepEdit);
    gateEditEvent(target, layer, latlng, setIsStepEdit);
    MapStatus.editType = 'line';
    setEditLayerData({
      flagType: 'line',
      layerType: type,
      layerName: toolTip,
      data: {
        id: layer.options.id,
        latlng: layer.getLatLngs(),
      },
    });
  });
};

// point 的編輯事件
export const pointEditEvent = (point, setIsStepEdit, setEditLayerData) => {
  // 先將 point 原本的狀態儲存起來
  point.options.isEditToolTip = false;
  point.options.isEditType = false;
  // 當拖曳開始時
  point.on('dragstart', () => {
    const { centerPosition } = MapStatus;
    const { rotationAngle } = MapStatus.mapMarker.options;

    // 移動時關閉地圖拖拉功能、tool tip
    MapStatus.map.dragging.disable();
    point.closeTooltip();

    point.options.undoLatLng = getOriginLeafletPosition(point.getLatLng(), centerPosition, rotationAngle);

    // 有觸發拖曳將 isEdit 變更為有編輯過
    point.options.isEdit = true;

    MapStatus.editLayer = point;
    store.dispatch(setCurrentItem({}));
  });

  // 拖曳期間更新精準位置座標
  point.on('drag', (e) => {
    const { latlng } = e;
    const { centerPosition } = MapStatus;
    const { rotationAngle } = MapStatus.mapMarker.options;
    setEditLayerData({
      flagType: 'point',
      layerType: point.options.type,
      layerName: point.options.toolTip,
      data: {
        id: point.options.id,
        angle: point.options.rosAngle || 0,
        latlng: getOriginLeafletPosition(latlng, centerPosition, rotationAngle),
      },
    });
  });

  // 當拖曳結束時，nodeIsDrag 狀態改為：未拖曳
  point.on('dragend', () => {
    const { centerPosition } = MapStatus;
    const { rotationAngle } = MapStatus.mapMarker.options;
    // 恢復地圖拖拉功能
    MapStatus.map.dragging.enable();

    // 如果目前標記名稱是打開的話則重新設定 tool tip 位置
    if (MapStatus.toolTip) point.openTooltip();

    // 重新設定 point 的未旋轉前座標 originLatLng
    point.options.originalLatLng = getOriginLeafletPosition(point.getLatLng(), centerPosition, rotationAngle);

    // 如果有編輯過才加入 steps
    if (point.options.isEdit) {
      // 將 point 編輯後的的狀態儲存起來
      point.options.redoLatLng = getOriginLeafletPosition(point.getLatLng(), MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);
      setIsStepEdit(true);
      point.options.isEdit = false;
    }
  });
};

// 新增redux 需用的point 的編輯事件
export const pointEditEventByRedux = (point, setLayerToolMode) => {
  const { _leaflet_id, options } = point;
  // 先將 point 原本的狀態儲存起來
  point.options.isEditToolTip = false;
  point.options.isEditType = false;
  let undoPayload = {};
  let startLatLng = null;
  point.on('mousedown', () => {
    if (store.getState().mapSlice.tools.addOnly || MapStatus.mode === 'path') {
      point.dragging.disable();
      point.dragging.enable();
      return;
    }
    clearEditNodePoint();
    clearLayerLine();
    // 關閉排序畫面
    clearUVCZoneList(false);
    // 先清除畫面上所有窄門框線
    disableNarrowGateBorder();
    setLayerToolMode('select');
    store.dispatch(setCurrentItem({}));
    drawLayerLine(point);
    undoPayload = { ...point.getLatLng() };

    if (options.type === 'Charger') {
      store.dispatch(setCurrentGroupItem({ id: point.options.groupID, type: options.type, mode: 'edit' }));
    }
  });
  // 當拖曳開始時
  point.on('dragstart', () => {
    if (store.getState().mapSlice.tools.addOnly) return;
    // 移動時關閉地圖拖拉功能、tool tip
    if (!siteTypes.includes(options.type)) {
      MapStatus.map.dragging.disable();
      point.closeTooltip();
    }
    // 有觸發拖曳將 isEdit 變更為有編輯過
    options.isEdit = true;
    nodeIsDrag = true;
    startLatLng = point.getLatLng();
  });

  // 當拖曳結束時，nodeIsDrag 狀態改為：未拖曳
  point.on('dragend', () => {
    if (store.getState().mapSlice.tools.addOnly) return;
    // 恢復地圖拖拉功能
    MapStatus.map.dragging.enable();
    // 檢查是否僅包含在單個地圖區塊內
    if (siteTypes.includes(point.options.type)) {
      const { isInMapArea } = checkMarkerGroupInMapArea({ groupID: point.options.groupID, types: siteTypes });
      if (isInMapArea) {
        point.setLatLng(startLatLng);
        return;
      }
    }

    // 如果目前標記名稱是打開的話則重新設定 tool tip 位置
    if (MapStatus.toolTip) point.openTooltip();

    // 如果有編輯過才加入 steps
    if (point.options.isEdit) {
      point.options.isEdit = false;
    }
    store.dispatch(updatePoint({ _id: _leaflet_id, ...point.getLatLng() }));
    store.dispatch(setCurrentItem({ type: options.type, _id: _leaflet_id, mode: 'edit' }));
    if (nodeIsDrag) updateStepsInRedux('move', undoPayload, { ...point.getLatLng() });
    nodeIsDrag = false;
    undoPayload = {};
  });
  point.on('mouseup', () => {
    setTimeout(() => {
      if (store.getState().mapSlice.tools.addOnly) return;
      store.dispatch(setCurrentItem({ type: options.type, _id: _leaflet_id, mode: 'edit' }));
    }, 100);
    // if (store.getState().mapSlice.tools.addOnly) return;
    // store.dispatch(setCurrentItem({ type: options.type, _id: _leaflet_id, mode: 'edit' }));
  });
};

// site 的編輯事件
export const siteEditEvent = (site, setIsStepEdit, setEditLayerData) => {
  // 先將 site 原本的狀態儲存起來
  site.options.isEditToolTip = false;
  site.options.isEditType = false;
  // 當拖曳開始時
  site.on('dragstart', () => {
    const { centerPosition } = MapStatus;
    const { rotationAngle } = MapStatus.mapMarker.options;

    // 移動時關閉地圖拖拉功能、tool tip
    if (site.options.type !== 'ParkingArea' && site.options.type !== 'Charger') {
      MapStatus.map.dragging.disable();
      site.closeTooltip();
    }

    site.options.undoLatLng = getOriginLeafletPosition(site.getLatLng(), centerPosition, rotationAngle);

    // 有觸發拖曳將 isEdit 變更為有編輯過
    site.options.isEdit = true;

    MapStatus.editLayer = site;
  });

  // 拖曳期間更新精準位置座標
  site.on('drag', (e) => {
    const { latlng } = e;
    const { centerPosition } = MapStatus;
    const { rotationAngle } = MapStatus.mapMarker.options;
    setEditLayerData({
      flagType: 'site',
      layerType: site.options.type,
      layerName: site.options.toolTip,
      data: {
        id: site.options.id,
        angle: site.options.rosAngle,
        latlng: getOriginLeafletPosition(latlng, centerPosition, rotationAngle),
      },
    });
  });

  // 當拖曳結束時，nodeIsDrag 狀態改為：未拖曳
  site.on('dragend', () => {
    const { centerPosition } = MapStatus;
    const { rotationAngle } = MapStatus.mapMarker.options;
    // 恢復地圖拖拉功能
    MapStatus.map.dragging.enable();

    // 如果目前標記名稱是打開的話則重新設定 tool tip 位置
    if (MapStatus.toolTip) site.openTooltip();

    // 重新設定 point 的未旋轉前座標 originLatLng
    site.options.originalLatLng = getOriginLeafletPosition(site.getLatLng(), centerPosition, rotationAngle);

    // 如果有編輯過才加入 steps
    if (site.options.isEdit) {
      // 將 point 編輯後的的狀態儲存起來
      site.options.redoLatLng = getOriginLeafletPosition(site.getLatLng(), MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);
      setIsStepEdit(true);
      site.options.isEdit = false;
    }
  });
};

// task point 的編輯事件
export const taskPointEditEvent = (point, stateSet, baseMapControl) => {
  let undoPayload = {};

  point.on('click', (e) => {
    if (MapStatus.selectPoint !== null) recoveryPointNotSelect(baseMapControl.zoomSize);
    const { id, pathId } = e.target.options;
    const { selectPath } = MapStatus;

    if (id === selectPath.pointId && pathId === selectPath.pathId) {
      MapStatus.selectPoint = null;
      stateSet.setSelectPath({ type: 'currentPoint', pathId: '', pointId: '' });
      MapStatus.selectPath = { type: 'currentPoint', pathId: '', pointId: '' };
    } else {
      const selectObj = {
        pathId,
        pointId: id,
        type: 'taskPoint',
      };

      MapStatus.selectPoint = point;
      point.setIcon(IconStyle.TaskSelectIcon(baseMapControl.zoomSize));
      stateSet.setSelectPath(selectObj);
      MapStatus.selectPath = selectObj;
    }

    stateSet.setRightLayoutType('task');
  });

  // 當拖曳開始時
  point.on('mousedown', (e) => {
    const { id, pathId } = e.target.options;
    undoPayload = { id, pathId, ...point.getLatLng() };
  });

  point.on('dragstart', () => {
    nodeIsDrag = true;
  });

  point.on('dragend', (e) => {
    const { id, pathId } = e.target.options;
    const { centerPosition, pathData } = MapStatus;
    const { rotationAngle } = MapStatus.mapMarker.options;
    const arr = [...pathData];
    const newLatLng = point.getLatLng();

    // 重新設定 point 的未旋轉前座標 originLatLng
    point.options.originalLatLng = getOriginLeafletPosition(newLatLng, centerPosition, rotationAngle);

    arr.forEach((path) => {
      if (path.pathId === pathId) {
        path.taskPoint.forEach((taskPoint) => {
          if (taskPoint.pointId === id) {
            taskPoint.x = point.options.originalLatLng.lng.toFixed(2);
            taskPoint.y = point.options.originalLatLng.lat.toFixed(2);

            // 如果該 task point 的 list 是展開狀態，則更新 x/y 的 input value
            if (taskPoint.isOpen) {
              const inputX = document.querySelector(`#taskPointX_${pathId}_${id}`);
              const inputY = document.querySelector(`#taskPointY_${pathId}_${id}`);
              inputX.value = point.options.originalLatLng.lng.toFixed(2);
              inputY.value = point.options.originalLatLng.lat.toFixed(2);
            }

            if (path.actionApiType !== 'post') path.actionApiType = 'put';
          }
        });
      }
    });

    stateSet.setPathData(arr);
    MapStatus.pathData = arr;
    groupByPathLine(baseMapControl.zoomSize);
    MapStatus.isPathModeEdit = true;
    if (nodeIsDrag) updateStepsInRedux('move', undoPayload, { id, pathId, ...point.getLatLng() });
    nodeIsDrag = false;
  });
};

// dock point 的編輯事件
export const dockPointEditEvent = (point, stateSet) => {
  point.on('click', (e) => {
    if (MapStatus.selectPoint !== null) recoveryPointNotSelect();
    const { id } = e.target.options;
    const { selectPath } = MapStatus;
    const selectObj = { ...selectPath };

    if (selectObj.pointId === id) {
      selectObj.type = '';
      selectObj.pathId = '';
      selectObj.pointId = '';
    } else {
      selectObj.type = 'dock';
      selectObj.pathId = '';
      selectObj.pointId = id;

      MapStatus.layerArr.dockPoint.forEach((dock) => {
        if (dock.options.id === id) {
          dock.setIcon(IconStyle.DockSelectIcon());
          MapStatus.selectPoint = dock;
        }
      });
    }

    stateSet.setSelectPath(selectObj);
    MapStatus.selectPath = selectObj;
    stateSet.setRightLayoutType('dock');
  });

  point.on('dragend', (e) => {
    const { id } = e.target.options;
    const { centerPosition, dockData } = MapStatus;
    const { rotationAngle } = MapStatus.mapMarker.options;
    const arr = JSON.parse(JSON.stringify(dockData));
    const newLatLng = point.getLatLng();

    // 重新設定 point 的未旋轉前座標 originLatLng
    point.options.originalLatLng = getOriginLeafletPosition(newLatLng, centerPosition, rotationAngle);

    arr.forEach((dock) => {
      if (dock.id === id) {
        dock.x = point.options.originalLatLng.lng.toFixed(2);
        dock.y = point.options.originalLatLng.lat.toFixed(2);
        const inputX = document.querySelector(`#dockX_${id}`);
        const inputY = document.querySelector(`#dockY_${id}`);
        inputX.value = point.options.originalLatLng.lng.toFixed(2);
        inputY.value = point.options.originalLatLng.lat.toFixed(2);

        if (dock.apiType === 'init') dock.apiType = 'put';
      }
    });

    stateSet.setDockData(arr);
    MapStatus.dockData = arr;
    MapStatus.isPathModeEdit = true;
  });
};

// home point 的編輯事件
export const homePointEditEvent = (point, stateSet) => {
  point.on('click', (e) => {
    if (MapStatus.selectPoint !== null) recoveryPointNotSelect();
    const { id } = e.target.options;
    const { selectPath } = MapStatus;
    const selectObj = { ...selectPath };

    if (selectObj.pointId === id) {
      selectObj.type = '';
      selectObj.pathId = '';
      selectObj.pointId = '';
    } else {
      selectObj.type = 'home';
      selectObj.pathId = '';
      selectObj.pointId = id;

      MapStatus.layerArr.homePoint.forEach((home) => {
        if (home.options.id === id) {
          home.setIcon(IconStyle.HomeSelectIcon());
          MapStatus.selectPoint = home;
        }
      });
    }

    stateSet.setSelectPath(selectObj);
    MapStatus.selectPath = selectObj;
    stateSet.setRightLayoutType('home');
  });

  point.on('dragend', (e) => {
    const { id } = e.target.options;
    const { centerPosition, homeData } = MapStatus;
    const { rotationAngle } = MapStatus.mapMarker.options;
    const arr = JSON.parse(JSON.stringify(homeData));
    const newLatLng = point.getLatLng();

    // 重新設定 point 的未旋轉前座標 originLatLng
    point.options.originalLatLng = getOriginLeafletPosition(newLatLng, centerPosition, rotationAngle);

    arr.forEach((home) => {
      if (home.id === id) {
        home.x = point.options.originalLatLng.lng.toFixed(2);
        home.y = point.options.originalLatLng.lat.toFixed(2);
        const inputX = document.querySelector(`#homeX_${id}`);
        const inputY = document.querySelector(`#homeY_${id}`);
        inputX.value = point.options.originalLatLng.lng.toFixed(2);
        inputY.value = point.options.originalLatLng.lat.toFixed(2);

        if (home.apiType === 'init') home.apiType = 'put';
      }
    });

    stateSet.setHomeData(arr);
    MapStatus.homeData = arr;
    MapStatus.isPathModeEdit = true;
  });
};

// 綁定地圖的錨點事件
export const activeAnchorEvent = (setState) => {
  console.log('map status ', MapStatus);
  MapStatus.map.on('mousedown', (e) => {
    const arrowLatLng = [e.latlng, e.latlng];
    createAnchorLine(arrowLatLng);

    MapStatus.map.on('mousemove', (move_e) => {
      arrowLatLng[1] = move_e.latlng;
      MapStatus.AnchorLine.setLatLngs(arrowLatLng);
    });
  });

  MapStatus.map.on('mouseup', async () => {
    const latLng = MapStatus.AnchorLine.getLatLngs();
    const robotOldPosition = MapStatus.robot.getLatLng();
    const robotOldHeading = MapStatus.robot.options.rotationAngle;
    const robotLatLng = latLng[0];
    const angle = Math.atan2((latLng[1].lat - latLng[0].lat), (latLng[1].lng - latLng[0].lng));
    const robotHeading = angle * (180 / Math.PI);
    let leafletAngle = Math.abs(robotHeading - 90);

    if (robotHeading > 90 && robotHeading <= 180) leafletAngle = 360 - robotHeading + 90;

    MapStatus.robot.setLatLng(robotLatLng);
    MapStatus.robot.setRotationAngle(leafletAngle);
    MapStatus.map.off('mousemove');
    MapStatus.AnchorLine.remove();
    MapStatus.AnchorLine = null;
    MapStatus.robot.options.rosAngle = robotHeading;
    MapStatus.newRobotPosition = {
      x: robotLatLng.lng,
      y: robotLatLng.lat,
      z: 0,
      heading: robotHeading, // 機器人面對的位置也要轉向
    };

    setState.setIsLoading(true);
    setState.setLoadingText('BIND_ROBOT_POSITION');
    setState.setMessageType('bind');
    setState.setMessageTitle('BIND_ROBOT_POSITIONING');

    try {
      const robotID = store.getState().bindMapSlice.selectRobot.id;
      const data = {
        position_info: { ...MapStatus.newRobotPosition },
      };
      await apiDeviceAutoLocalization({ device_id: robotID, data });
      const robotInfo = await apiBindDeviceStatus({ device_id: robotID });
      store.dispatch(setSelectRobot({
        scanPoint: robotInfo.data.status.lidar_point,
        currentLocation: {
          x: robotInfo.data.status.status.current_pose.x,
          y: robotInfo.data.status.status.current_pose.y,
          heading: robotInfo.data.status.status.current_pose.heading,
        },
      }));
      clearAllScanPoint();
      setScanPointIntoMap(robotInfo.data.status.lidar_point, setState.resolution);
      setState.setMessageOpen(true);
      setState.setMessageText('BIND_ROBOT_POSITION_SUCCESS');
      setState.setIsLoading(false);
    } catch (error) {
      console.log(error);
      setState.setMessageOpen(true);
      setState.setMessageText('BIND_ROBOT_POSITION_FAIL');
      setState.setIsLoading(false);
      MapStatus.robot.setLatLng(robotOldPosition);
      MapStatus.robot.setRotationAngle(robotOldHeading);
    }
  });
};

export const closeAnchorEvent = () => {
  MapStatus.map.off('mousedown');
  MapStatus.map.off('mouseup');
};
