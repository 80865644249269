import { request } from './request.js';
import getToken from '../common/getToken.js';
// import { requestImage, aspectRequest } from './requestImage.js';
import { aspectRequest } from './requestImage.js';

//  Device 相關的 api

// 取得all device 資訊
export const apiDeviceInfoList = ({ device_id }) => request.get(`framework/Device?device_id=${device_id}`, getToken());

// 新增 device
// export const apiDeviceCreate = ({ type, data }) => request.post(`framework/devices/${type}`, data, getToken());
export const apiDeviceCreate = ({ type, data }) => aspectRequest.post(`/device/${type}`, data, getToken());

// 刪除 device
// export const apiDeviceRemove = ({ type, id }) => request.delete(`framework/devices/${type}/${id}`, getToken());
export const apiDeviceRemove = ({ type, id }) => aspectRequest.delete(`/device/${type}/${id}`, getToken());

// export const appDeviceBatchDelete = ({ type, id }) => request.delete(`framework/devices/bulk-delete/${type}?device_list=[${id}]`, getToken());
export const appDeviceBatchDelete = async ({ type, id }) => {
  const delete_ids = [];
  console.log('id_List:', id);
  // console.log('type:', type);
  const idList = id.split(',').map(Number);
  for (const deviceId of idList) {
    console.log('deviceId:', deviceId);
    await aspectRequest.delete(`/device/${type}/${deviceId}/`, getToken());
    delete_ids.push(deviceId);
  }
  return {
    status: 200,
    data: {
      message: 'Success delete devices: {$delete_ids}',
    },
  };
};
// 修改 device
export const apiDeviceEdit = (id, data) => request.patch(`framework/Device/${id}/`, data, getToken());

// 查詢單一 device 資訊
export const apiDeviceInfo = (type, id) => request.get(`framework/devices/${type}/${id}/`, getToken());

export const apiGetDeviceListByParams = (type, query) => request.get(`framework/devices/${type}`, {
  ...getToken(),
  ...(query && { params: query }),
});

// 離開 device 充電站
export const apiPostChargingAreaLeave = (device_id, data = {}) => request.post(`framework/Device/${device_id}/undocking`, data, getToken());

// 批次新增 device
export const apiDeviceImport = ({ file }) => {
  const postData = new FormData();

  if (file) postData.append('file', file);

  // return requestImage.post('framework/Device/Import', postData, getToken());
  return aspectRequest.post('/device/import', postData, getToken());
};

export const apiDeviceConsumables = ({ device_id, device_type }) => request.get(`framework/Device/${device_id}/consumables?device_type=${device_type}`, getToken());

// 取得 device 目前的位置等資訊
export const apiBindDeviceStatus = ({ device_id }) => request.get(`framework/devices/${device_id}/status?info=true&lidar=true`, getToken());

// device 輔助定位/手動定位/綁定地圖
export const apiDeviceAutoLocalization = ({ device_id, data }) => request.post(`framework/Device/${device_id}/auto_localization/`, data, getToken());

export const apiDeviceStatus = (device_id) => request.get(`framework/devices/${device_id}/status`, getToken());

export const apiDeviceCommand = (device_id, data) => request.post(`framework/Device/${device_id}/general_command/`, data, getToken());

export const apiDevicesUpdate = (type, id, data) => request.patch(`framework/devices/${type}/${id}/`, data, getToken());
