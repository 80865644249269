export const AppConfig = {
  API_URL: window._config_.API_URL || process.env.API_URL || 'https://amr-api.cloud.wifundity.com',
  CMS_URL: window._config_.CMS_URL || process.env.CMS_URL || 'https://amrcms-api.cloud.wifundity.com',
  ROS_URL: window._config_.ROS_URL || process.env.ROS_URL || 'http://ros.southeastasia.cloudapp.azure.com:8080/wisbot/api',
  ASPECT_URL: window._config_.ASPECT_URL || process.env.ASPECT_URL || 'https://portal.cloud.wifundity.com/wif-wic-aspect',
  WICENTRAL_URL: window._config_.WICENTRAL_URL || process.env.WICENTRAL_URL || 'https://portal.cloud.wifundity.com/notification',
  MQTT_HOST: window._config_.MQTT_HOST || process.env.MQTT_HOST || 'wss://mosquitto.cloud.wifundity.com',
  MQTT_USERNAME: window._config_.MQTT_USERNAME || process.env.MQTT_USERNAME || 'wifundity',
  MQTT_PASSWORD: window._config_.MQTT_PASSWORD || process.env.MQTT_PASSWORD || 'WiFundity',
  GROUP_ID: window._config_.GROUP_ID || process.env.GROUP_ID || 6,
  SERVICE_DISABLE: window._config_.SERVICE_DISABLE || process.env.SERVICE_DISABLE || false,
  DEFAULT_SITE_NAME: 'unname_site_01',
  UI_TYPE: 'cloud',
  UI_VERSION: 'v0.5.0',
  UI_DESCRIPTION: '1.地圖管理修正 \\n 2.巡檢管理增加',
  API_VERSION: 'v0.0.13',
  ROS_VERSION: 'v0.5.11',
  AZURE_HOST: 'https://api.stage.ai-amaze.com',
  AZURE_EMAIL: 'wistronxamaze@gmail.com',
  AZURE_PASSWORD: '4Enr&8xSsgHb',
};

// if (getIp === 'localhost' || getIp === '127.0.0.0') {
//   MyConfig.API_URL = window._config_.API_URL || process.env.API_URL;
//   MyConfig.ROS_URL = window._config_.ROS_URL || process.env.ROS_URL;
//   MyConfig.MQTT_HOST = window._config_.MQTT_HOST || process.env.MQTT_HOST;
//   MyConfig.DEFAULT_SITE_NAME = window._config_.DEFAULT_SITE_NAME || process.env.DEFAULT_SITE_NAME;
// }

// 開放 IP 給平板測試用
// MyConfig.API_URL = 'http://192.168.16.159:8080/wisbot/api';
// MyConfig.MQTT_HOST = 'ws://192.168.16.159:9001/mqtt/';
// MyConfig.DEFAULT_SITE_NAME = window._config_.DEFAULT_SITE_NAME || process.env.DEFAULT_SITE_NAME;
